import * as React from "react";
import {
    Edit, ReferenceInput, SelectInput,
    SimpleForm,
    TextInput,
    useRecordContext,
} from 'react-admin';

export const ProductEdit = (props) => {

    const EditTitle = () => {
        const record = useRecordContext();
        return <span>Edit product {record ? `"${record.title}"` : ''}</span>;
    };

    return (
        <Edit {...props} redirect="list" title={<EditTitle/>}>
            <SimpleForm>
                <TextInput source="title"/>
                <ReferenceInput source="type" reference="reference/product-provider/product-types">
                    <SelectInput optionText="title"/>
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    );
}
