import config from '../config/config'
import Cookies from 'js-cookie'

export const STORAGE_ADAPTER_COOKIES_SYNC = 'STORAGE_ADAPTER_COOKIES_SYNC'
export const STORAGE_ADAPTER_LOCALSTORAGE_SYNC = 'STORAGE_ADAPTER_LOCALSTORAGE_SYNC'

class LocalStorageAdapterSync {
  setItem = (key, val) => localStorage.setItem(key, val)
  getItem = key => localStorage.getItem(key)
  removeItem = key => localStorage.removeItem(key)
}

class CookiesAdapterSync {
  setItem = (key, val) => Cookies.set(key, val, { expires: 7 })
  getItem = key => Cookies.get(key)
  removeItem = key => Cookies.remove(key)
}

export const StorageFactory = () => {
  switch (config.APP_STORAGE_ADAPTER) {
    case STORAGE_ADAPTER_LOCALSTORAGE_SYNC:
      return new LocalStorageAdapterSync()

    case STORAGE_ADAPTER_COOKIES_SYNC:
    default:
      return new CookiesAdapterSync()
  }
}