import React from 'react'
import {Resource} from 'react-admin'
import {ProductList} from "./List";
import {ProductEdit} from "./Edit";
import {ProductCreate} from "./Create";


export default permissions => [
    <Resource
        name="reference/product-provider/products"
        list={ProductList}
        edit={ProductEdit}
        create={ProductCreate}
    />
]