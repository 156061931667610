import * as React from "react";
import {List, Datagrid, TextField, ReferenceField, ReferenceInput, SelectInput, TextInput} from 'react-admin';

export const LoanProgramList = (props) => {
    const postFilters = [
        <ReferenceInput source="productProvider" reference="reference/product-provider/product-providers">
            <SelectInput optionText="title"/>
        </ReferenceInput>,
        <TextInput label="Title" source="title" />,
    ];

    return (
        <List filters={postFilters} {...props} title="Available loan programmes">
            <Datagrid bulkActionButtons={false} rowClick="edit">
                <TextField source="title"/>
                <TextField source="marketingTitle"/>
                <TextField source="creditType"/>
                <ReferenceField source="productProvider" reference="reference/product-provider/product-providers">
                    <TextField source="title"/>
                </ReferenceField>
            </Datagrid>
        </List>
    );
}
