import * as React from "react";
import {List, Datagrid, TextField, ReferenceField} from 'react-admin';

export const ProductList = (props) => (
    <List {...props} title="Available products">
        <Datagrid bulkActionButtons={false} rowClick="edit">
            <TextField source="title"/>
            <ReferenceField source="type" reference="reference/product-provider/product-types">
                <TextField source="title"/>
            </ReferenceField>
        </Datagrid>
    </List>
);
