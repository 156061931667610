import React from 'react';
import { AppBar } from 'react-admin';
import {
  SpacerStyled,
  TypographyStyled
} from './style';

export const CustomAppBar = (props) => {
  return (
    <AppBar {...props}>
      <TypographyStyled
        variant="h5"
        color="inherit"
        id="react-admin-title"
      >
        <SpacerStyled/>
      </TypographyStyled>
    </AppBar>
  )
}
