import {STORAGE_ADAPTER_COOKIES_SYNC} from "../utils/StorageFactory";

const Config = {
  api: process.env.REACT_APP_API_ENTRYPOINT,
  root:  process.env.REACT_APP_API_ROOT,
  themeConfig: require(`../theme/${process.env.REACT_APP_THEME}/config`).default,
  APP_STORAGE_ADAPTER: STORAGE_ADAPTER_COOKIES_SYNC
}

console.log("Config", Config);
export default Config;
