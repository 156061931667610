import * as React from "react";
import {useState} from "react";
import Box from "@mui/material/Box";
import DescriptionIcon from "@mui/icons-material/Description";
import {
    MenuItemLink,
    useSidebarState,
} from "react-admin";

const Menu = ({dense = false}) => {
    const [state, setState] = useState({
        menuCatalog: true,
        menuSales: true,
        menuCustomers: true,
        menuReferenceBooks: true,
        menuBanks: true
    });
    const [open] = useSidebarState();

    const handleToggle = (menu) => {
        setState((state) => ({...state, [menu]: !state[menu]}));
    };

    return (
        <Box
            sx={{
                width: open ? 250 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: (theme) =>
                    theme.transitions.create("width", {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            <MenuItemLink
                to={{
                    pathname: "/reference/product-provider/product-providers",
                    search: `filter=${JSON.stringify({
                        activeFlag: true
                    })}`,
                }}
                state={{_scrollToTop: true}}
                primaryText="Banks"
                leftIcon={<DescriptionIcon/>}
                dense={dense}
            />
            <MenuItemLink
                to={{
                    pathname: "/reference/product-provider/loan-programs",
                    search: `filter=${JSON.stringify({
                        activeFlag: true
                    })}`,
                }}
                state={{_scrollToTop: true}}
                primaryText="Loan programs"
                leftIcon={<DescriptionIcon/>}
                dense={dense}
            />
            <MenuItemLink
                to={{
                    pathname: "/reference/product-provider/product-types"
                }}
                state={{_scrollToTop: true}}
                primaryText="Product types"
                leftIcon={<DescriptionIcon/>}
                dense={dense}
            />
            <MenuItemLink
                to={{
                    pathname: "/reference/product-provider/products"
                }}
                state={{_scrollToTop: true}}
                primaryText="Products"
                leftIcon={<DescriptionIcon/>}
                dense={dense}
            />
        </Box>
    );
};

export default Menu;
