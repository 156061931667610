import * as React from "react";
import {BooleanInput, Edit, required, SimpleForm, TextInput, useRecordContext,} from 'react-admin';

export const ProductProviderEdit = (props) => {

    const EditTitle = () => {
        const record = useRecordContext();
        return <span>Edit bank {record ? `"${record.title}"` : ''}</span>;
    };

    const transform = data => ({
        ...data,
        type: `bank`
    });

    return (
        <Edit {...props} redirect="list" title={<EditTitle/>}  transform={transform}>
            <SimpleForm>
                <TextInput source="title" validate={[required()]}/>
                <TextInput source="code" validate={[required()]}/>
                <TextInput source="bank.email" validate={[required()]}/>
                <BooleanInput source="isActive" validate={[required()]}/>
            </SimpleForm>
        </Edit>
    );
}
