import React from 'react'
import {Resource} from 'react-admin'
import {ProductTypeList} from "./List";
import {ProductTypeEdit} from "./Edit";
import {ProductTypeCreate} from "./Create";


export default permissions => [
    <Resource
        name="reference/product-provider/product-types"
        list={ProductTypeList}
        edit={ProductTypeEdit}
        create={ProductTypeCreate}
    />
]