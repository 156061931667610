import * as React from "react";
import {List, Datagrid, TextField} from 'react-admin';

export const ProductTypeList = (props) => (
    <List {...props} title="Available product types">
        <Datagrid bulkActionButtons={false} rowClick="edit">
            <TextField source="title"/>
            <TextField source="code"/>
        </Datagrid>
    </List>
);
