const ThemeConfig = {
  company: 'Fleetify',
  defaultTitle: 'Finance Products Settings',
  primary: '#bd3830',
  secondary: '#007DFF',
  manifest: '/theme/default/manifest.json',
  logo: '/theme/default/logo256.png',
  favicon: '/theme/default/favicon.ico',
}

export default ThemeConfig;
