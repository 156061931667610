import React from 'react'
import { Resource } from 'react-admin'
import {ProductProviderList} from "./List";
import {ProductProviderEdit} from "./Edit";
import {ProductProviderCreate} from "./Create";


export default permissions => [
    <Resource
        name="reference/product-provider/product-providers"
        list={ProductProviderList}
        edit={ProductProviderEdit}
        create={ProductProviderCreate}
    />
]