import * as React from "react";
import {List, Datagrid, TextField, BooleanField} from 'react-admin';

export const ProductProviderList = (props) => (
    <List {...props} title="Available banks">
        <Datagrid bulkActionButtons={false} rowClick="edit">
            <TextField source="title"/>
            <TextField source="code"/>
            <TextField source="bank.email"/>
            <BooleanField source="isActive"/>
        </Datagrid>
    </List>
);
