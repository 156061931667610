import React from 'react';
import {
  fetchHydra as baseFetchHydra,
  HydraAdmin,
  hydraDataProvider,
  useIntrospection
} from '@api-platform/admin';
import { Admin, Resource, ListGuesser, EditGuesser, ShowGuesser } from 'react-admin';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import { CustomLayout } from './Layout/Layout';
import { createHashHistory } from 'history';
import config from './config/config';
import { parseHydraDocumentation } from '@api-platform/api-doc-parser';
import { patchData } from "./utils/patchData";
import Resources from './components/resources'

const history = createHashHistory();

const theme = createTheme({
  palette: {
    primary: {
      main: config.themeConfig.primary
    },
    secondary: {
      main: config.themeConfig.secondary
    }
  },
})

const ENTRYPOINT = process.env.REACT_APP_API_ENTRYPOINT;

const getHeaders = () => {};

const wrapToPromise = (obj) => {
  return new Promise((resolve) => setTimeout(() => resolve(obj), 10));
};

const fetchHydra = async (url, options = {}) => {
  const result = await baseFetchHydra(url, {
    ...options,
    headers: getHeaders,
  });
  patchData(result.json);
  return wrapToPromise(result);
};

const apiHydraDocumentationParser = async () => {
  try {
    const { api } = await parseHydraDocumentation(ENTRYPOINT, { headers: getHeaders() });
    return { api };
  } catch (result) {
    console.log(result)
    throw result;
  };
};

const dataProviderHydra = () => hydraDataProvider({
  entrypoint: ENTRYPOINT,
  httpClient: fetchHydra,
  apiDocumentationParser: apiHydraDocumentationParser,
  useEmbedded: true
});

const App = () => {

  return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{config.themeConfig.defaultTitle}</title>
          <link rel="shortcut icon" href={config.themeConfig.favicon} />
        </Helmet>
        <ThemeProvider theme={theme}>
          <HydraAdmin
              entrypoint={ENTRYPOINT}
              theme={theme}
              layout={CustomLayout}
              disableTelemetry
          >
            {
              permissions => Resources(permissions)
            }
          </HydraAdmin>
        </ThemeProvider>
      </>
  );

}

export default App;
