import * as React from "react";
import {
    SimpleForm,
    BooleanInput,
    TextInput,
    Create,
    required
} from 'react-admin';

export const ProductProviderCreate = (props) => {

    const transform = data => ({
        ...data,
        type: `bank`
    });

    return (
        <Create {...props}  redirect="list" title="Create new bank" transform={transform}>
            <SimpleForm sx={{maxWidth: 1200}}>
                <TextInput source="title" validate={[required()]} />
                <TextInput source="code" validate={[required()]} />
                <TextInput source="bank.email" validate={[required()]} />
                <BooleanInput source="isActive" validate={[required()]} />
            </SimpleForm>
        </Create>
    );
}
