import styled from 'styled-components';
import Typography from '@mui/material/Typography';

export const SpacerStyled = styled.span`
  flex: 1;
`;

export const TypographyStyled = styled(Typography)`
  flex: 1;
  textOverflow: ellipsis;
  whiteSpace: nowrap;
  overflow: hidden;
`;