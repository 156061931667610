import React from 'react'
import { Resource } from 'react-admin'
import {LoanProgramList} from "./List";
import {LoanProgramEdit} from "./Edit";
import {LoanProgramCreate} from "./Create";


export default permissions => [
    <Resource
        name="reference/product-provider/loan-programs"
        list={LoanProgramList}
        edit={LoanProgramEdit}
        create={LoanProgramCreate}
    />
]