import * as React from "react";
import {
    SimpleForm,
    TextInput,
    Create,
} from 'react-admin';

export const ProductCreate = (props) => {

    return (
        <Create {...props}  redirect="list" title="Create new product">
            <SimpleForm>
                <TextInput source="title" />
            </SimpleForm>
        </Create>
    );
}
