import React from 'react';
import {Layout} from 'react-admin';
import {CustomAppBar} from './AppBar';
import Menu from './Menu';
import { ReactQueryDevtools } from 'react-query/devtools';

export const CustomLayout = (props) => {
    return (
        <>
            <Layout
                {...props}
                appBar={CustomAppBar}
                menu={Menu}
            />
            <ReactQueryDevtools initialIsOpen={false} />
        </>
    )
}
