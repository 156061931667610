import * as React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    useRecordContext,
} from 'react-admin';

export const ProductTypeEdit = (props) => {

    const EditTitle = () => {
        const record = useRecordContext();
        return <span>Edit loan program {record ? `"${record.title}"` : ''}</span>;
    };

    return (
        <Edit {...props} redirect="list" title={<EditTitle/>}>
            <SimpleForm>
                <TextInput source="title"/>
                <TextInput source="code"/>
            </SimpleForm>
        </Edit>
    );
}
