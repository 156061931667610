import LoanProgram from './LoanProgram/resources'
import ProductProvider from './ProductProvider/resources'
import ProductType from './ProductType/resources'
import Product from './Product/resources'

export default permissions => [
    ...ProductProvider(permissions),
    ...LoanProgram(permissions),
    ...ProductType(permissions),
    ...Product(permissions),
]
