import * as React from "react";
import {
    ArrayInput,
    BooleanInput,
    Create, number,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    TextInput
} from 'react-admin';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";

export const LoanProgramCreate = (props) => {

    const transform = data => {
        console.log("data", data);
        if (data.externalId === null) {
            data.externalId = 'remove this validation';
        }
        console.log("data1", data);
        return {
            ...data
        }
    }

    return (
        <Create {...props} redirect="list" transform={transform}>
            <SimpleForm sx={{maxWidth: 1200}}>
                <Typography variant="h5" gutterBottom>
                    Base programme data
                </Typography>
                <Box display={{xs: 'block', sm: 'flex', width: '100%'}} mt={{xs: 0, sm: '0.5em'}}>
                    <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                        <TextInput source="title" isRequired fullWidth validate={[required()]}/>
                    </Box>
                    <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                        <TextInput source="marketingTitle" isRequired fullWidth validate={[required()]}/>
                    </Box>
                    <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                        <TextInput source="externalId" label="External ID" helperText="Id in bank" fullWidth/>
                    </Box>
                </Box>
                <Box display={{xs: 'block', sm: 'flex', width: '100%'}}>
                    <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                        <ReferenceInput source="productProvider" sort={{field: 'title', order: 'ASC'}}
                                        reference="reference/product-provider/product-providers" isRequired>
                            <SelectInput optionText="title" fullWidth/>
                        </ReferenceInput>
                    </Box>
                    <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                        <SelectInput source="creditType" fullWidth  validate={[required()]} choices={[
                            {id: 'classical', name: 'Classic'},
                            {id: 'balloon', name: 'Ballon'},
                        ]}/>
                    </Box>
                    <Box flex={1} mr={{xs: 0, sm: '0.5em'}} mt={{xs: 0, sm: '0.5em'}}>
                        <TextInput source="formulaType" label="Formula" defaultValue="annuity" fullWidth/>
                    </Box>
                </Box>
                <Box display={{xs: 'block', sm: 'flex', width: '70%'}}>
                    <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                        <BooleanInput source="isIncomeProofRequired"/>
                    </Box>
                    <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                        <BooleanInput source="isActive"/>
                    </Box>
                </Box>


                <Typography variant="h5" gutterBottom>
                    Limits
                </Typography>

                <Box display={{xs: 'block', sm: 'flex', width: '50%'}} mt={{xs: 0, sm: '0.5em'}}>
                    <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                        <NumberInput source="carPriceMin" fullWidth validate={[required()]} InputProps={{
                            endAdornment: <InputAdornment position="start">AED</InputAdornment>,
                        }}/>
                    </Box>
                    <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                        <NumberInput source="carPriceMax" fullWidth validate={[required()]} InputProps={{
                            endAdornment: <InputAdornment position="start">AED</InputAdornment>,
                        }}/>
                    </Box>
                </Box>

                <Box display={{xs: 'block', sm: 'flex', width: '50%'}} mt={{xs: 0, sm: '0.5em'}}>
                    <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                        <NumberInput source="loanAmountMin" fullWidth validate={[required()]} InputProps={{
                            endAdornment: <InputAdornment position="start">AED</InputAdornment>,
                        }}/>
                    </Box>
                    <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                        <NumberInput source="loanAmountMax" fullWidth validate={[required()]} InputProps={{
                            endAdornment: <InputAdornment position="start">AED</InputAdornment>,
                        }}/>
                    </Box>
                </Box>

                <Box display={{xs: 'block', sm: 'flex', width: '50%'}} mt={{xs: 0, sm: '0.5em'}}>
                    <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                        <NumberInput source="dpMin" label="Downpayment min" max={100} min={0} validate={[required()]}
                                     fullWidth InputProps={{
                            endAdornment: <InputAdornment position="start">%</InputAdornment>,
                        }}/>
                    </Box>
                    <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                        <NumberInput source="dpMax" label="Downpayment max" max={100} min={0} validate={[required()]}
                                     fullWidth InputProps={{
                            endAdornment: <InputAdornment position="start">%</InputAdornment>,
                        }}/>
                    </Box>
                </Box>

                <Box display={{xs: 'block', sm: 'flex', width: '25%'}} mt={{xs: 0, sm: '0.5em'}}>
                    <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                        <NumberInput source="additionalAmountMax" label="Aadditional products, max" helperText="How much can be added to the loan" max={100}
                                     min={0} validate={[required()]} fullWidth
                                     InputProps={{
                                         endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                     }}/>
                    </Box>
                </Box>

                <Typography variant="h5" gutterBottom>
                    Rates
                </Typography>
                <ArrayInput source="termLimits" label="" validate={required()}>
                    <SimpleFormIterator inline>
                        <NumberInput source="termMin" helperText={false} validate={[number(), required()]}/>
                        <NumberInput source="termMax" helperText={false} validate={[number(), required()]}/>
                        <TextInput source="baseRate" label="Base rate, %" helperText={false} validate={[number(), required()]} />
                        <TextInput source="marketingRate" label="Marketing rate, %" helperText={false} validate={[number()]} />
                        <TextInput source="privilegeRate" label="Privilege rate, %" helperText={false} validate={[number()]} />
                        <BooleanInput source="isBankCustomerOnly" helperText={false}/>
                        <BooleanInput source="isActive" helperText={false}/>
                    </SimpleFormIterator>
                </ArrayInput>

                <Typography variant="h5" gutterBottom>
                    Restrictions
                </Typography>

                <ArrayInput source="carTypeLimits">
                    <SimpleFormIterator inline>
                        <SelectInput source="carType" choices={[
                            {id: 'new', name: 'New cars'},
                            {id: 'used', name: 'Used cars'},
                        ]}/>
                        <BooleanInput source="isActive" defaultValue={true} helperText={false}/>
                    </SimpleFormIterator>
                </ArrayInput>

                <ArrayInput source="carLimits">
                    <SimpleFormIterator inline>
                        <TextInput source="makeId" helperText={false}/>
                        <TextInput source="modelId" helperText={false}/>
                        <NumberInput source="discount" helperText={false}/>
                        <BooleanInput source="exclude" helperText={false}/>
                        <BooleanInput source="isActive" helperText={false}/>
                    </SimpleFormIterator>
                </ArrayInput>
                <ArrayInput source="carAgeLimits">
                    <SimpleFormIterator inline>
                        <NumberInput source="carAgeMin" helperText={false}/>
                        <NumberInput source="carAgeMax" helperText={false}/>
                        <BooleanInput source="isActive" helperText={false}/>
                    </SimpleFormIterator>
                </ArrayInput>


            </SimpleForm>
        </Create>
    );
}
